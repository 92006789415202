jQuery(document).ready(function() {

    // force cursor placement on input masked phone field only when empty of characters
    var maskedPhones = document.querySelectorAll('.gfield .ginput_container_phone input');
    for (i = 0; i < maskedPhones.length; ++i) {
        maskedPhones[i].addEventListener('mouseup', function(e) {
            if ( e.currentTarget.value.replace(/\(|\)|_|-|\./g,'').trim() == '' ) { e.currentTarget.setSelectionRange(0,0); }
        });
    }

});

jQuery(document).on('click', '.gform_button', function() {

    let formTarget = jQuery(this).parents().eq(1).prop('target');

    if (!formTarget.includes('gform_ajax')) {
        jQuery('<img id="gform_ajax_spinner_9" class="gform_ajax_spinner" src="https://' + window.location.hostname + '/wp-content/plugins/gravityforms/images/spinner.svg" alt="">').insertAfter(this);
    }

    jQuery(this).val('SENDING...').attr('style', 'pointer-events:none!important');

});