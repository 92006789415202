jQuery('.sg-live-chat, a[href="#sg-live-chat"]').click(function(event) {

	ga('send', 'event', 'cms chat', 'click', currentPageURL);

	if (cmsEnvironment == 'DEV' || cmsEnvironment == 'TEST') {
	
		var sgChatURL = 'https://test-app.salesgenie.com/Static/Chat';
	
	} else {
	
		var sgChatURL = 'https://app.salesgenie.com/Static/Chat';
	
	}
	
	var objWin = window.open(
		sgChatURL,
		'MyWindow1427656777',
		'toolbar=0,location=0,directories=0,status=0,menubar=0,scrollbars=0,resizable=1,left=' + ((screen.width - 470) / 2) + '	,width=470px,top=' + ((screen.height - 430) / 2) + ',height=430px');
	
	if (objWin != null) {
		objWin.window.focus();
	}

	event.preventDefault();

});