jQuery(document).ready(function() {

    var mediacodeQSP = getParameterByName('mediacode');    

    var basVendorQSP = getParameterByName('bas_vendor');

    var basphoneQSP = getParameterByName('bas_phone');

    var sfcidQSP = getParameterByName('sfcid');

    var gclidQSP = getParameterByName('gclid');

    var msclkidQSP = getParameterByName('msclkid');

    var utmsourceQSP = getParameterByName('utm_source');

    var utmmediumQSP = getParameterByName('utm_medium');

    var utmcampaignQSP = getParameterByName('utm_campaign');

    var utmtermQSP = getParameterByName('utm_term');

    var utmcontentQSP = getParameterByName('utm_content');

    var campaignidQSP = getParameterByName('campaignid');

    var adgroupidQSP = getParameterByName('adgroupid');

    var keywordQSP = getParameterByName('keyword');

    var matchtypeQSP = getParameterByName('matchtype');

    var networkQSP = getParameterByName('network');

    var deviceQSP = getParameterByName('device');

    var extensionidQSP = getParameterByName('extensionid');

    var basVendorCookie = Cookies.get('bas_vendor');

    var mediacodeCookie = Cookies.get('mediacode');

    var basphoneCookie = Cookies.get('bas_phone');

    var sfcidCookie = Cookies.get('sfcid');

    var gclidCookie = Cookies.get('gclid');

    var msclkidCookie = Cookies.get('msclkid');

    var utmsourceCookie = Cookies.get('utm_source');

    var utmmediumCookie = Cookies.get('utm_medium');

    var utmcampaignCookie = Cookies.get('utm_campaign');

    var utmtermCookie = Cookies.get('utm_term');

    var utmcontentCookie = Cookies.get('utm_content');

    var campaignidCookie = Cookies.get('campaignid');

    var adgroupidCookie = Cookies.get('adgroupid');

    var keywordCookie = Cookies.get('keyword');

    var matchtypeCookie = Cookies.get('matchtype');

    var networkCookie = Cookies.get('network');

    var deviceCookie = Cookies.get('device');

    var extensionidCookie = Cookies.get('extensionid');

    if(jQuery('.mediacode').length > 0){
       if(mediacodeQSP){
           jQuery('li.mediacode').find('input').val(mediacodeQSP);
        }
        else{
           jQuery('li.mediacode').find('input').attr('value', mediacodeCookie);
        }
    }
    if(jQuery('.gclid').length > 0){
        if(gclidQSP){
           jQuery('li.gclid').find('input').val(gclidQSP);
        }else{
           jQuery('li.gclid').find('input').attr('value', gclidCookie);
        }
    }
    if(jQuery('.bas_vendor').length > 0){

        if(basVendorQSP){
           jQuery('li.bas_vendor').find('input').val(basVendorQSP);
        }else{
           jQuery('li.bas_vendor').find('input').attr('value', basVendorCookie); 
        }
    }
    if(jQuery('.bas_phone').length > 0){

        if(basphoneQSP){
           jQuery('li.bas_phone').find('input').val(basphoneQSP);
        }else{
           jQuery('li.bas_phone').find('input').attr('value', basphoneCookie); 
        }
    }
    if(jQuery('.sfcid').length > 0){

        if(sfcidQSP){
           jQuery('li.sfcid').find('input').val(sfcidQSP);
        }else{
           jQuery('li.sfcid').find('input').attr('value', sfcidCookie); 
        }
    }
    if(jQuery('.msclkid').length > 0){

        if(msclkidQSP){
           jQuery('li.msclkid').find('input').val(msclkidQSP);
        }else{
           jQuery('li.msclkid').find('input').attr('value', msclkidCookie); 
        }
    }
    if(jQuery('.utm_source').length > 0){

        if(utmsourceQSP){
           jQuery('li.utm_source').find('input').val(utmsourceQSP);
        }else{
           jQuery('li.utm_source').find('input').attr('value', utmsourceCookie); 
        }
    }
    if(jQuery('.utm_medium').length > 0){

        if(utmmediumQSP){
           jQuery('li.utm_medium').find('input').val(utmmediumQSP);
        }else{
           jQuery('li.utm_medium').find('input').attr('value', utmmediumCookie); 
        }
    }
    if(jQuery('.utm_campaign').length > 0){

        if(utmcampaignQSP){
           jQuery('li.utm_campaign').find('input').val(utmcampaignQSP);
        }else{
           jQuery('li.utm_campaign').find('input').attr('value', utmcampaignCookie); 
        }
    }
    if(jQuery('.utm_term').length > 0){

        if(utmtermQSP){
           jQuery('li.utm_term').find('input').val(utmtermQSP);
        }else{
           jQuery('li.utm_term').find('input').attr('value', utmtermCookie); 
        }
    }
    if(jQuery('.utm_content').length > 0){

        if(utmcontentQSP){
           jQuery('li.utm_content').find('input').val(utmcontentQSP);
        }else{
           jQuery('li.utm_content').find('input').attr('value', utmcontentCookie); 
        }
    }
    if(jQuery('.campaignid').length > 0){

        if(campaignidQSP){
           jQuery('li.campaignid').find('input').val(campaignidQSP);
        }else{
           jQuery('li.campaignid').find('input').attr('value', campaignidCookie); 
        }
    }
    if(jQuery('.adgroupid').length > 0){

        if(adgroupidQSP){
           jQuery('li.adgroupid').find('input').val(adgroupidQSP);
        }else{
           jQuery('li.adgroupid').find('input').attr('value', adgroupidCookie); 
        }
    }
    if(jQuery('.keyword').length > 0){

        if(keywordQSP){
           jQuery('li.keyword').find('input').val(keywordQSP);
        }else{
           jQuery('li.keyword').find('input').attr('value', keywordCookie); 
        }
    }
    if(jQuery('.matchtype').length > 0){

        if(matchtypeQSP){
           jQuery('li.matchtype').find('input').val(matchtypeQSP);
        }else{
           jQuery('li.matchtype').find('input').attr('value', matchtypeCookie); 
        }
    }
    if(jQuery('.network').length > 0){

        if(networkQSP){
           jQuery('li.network').find('input').val(networkQSP);
        }else{
           jQuery('li.network').find('input').attr('value', networkCookie); 
        }
    }
    if(jQuery('.device').length > 0){

        if(deviceQSP){
           jQuery('li.device').find('input').val(deviceQSP);
        }else{
           jQuery('li.device').find('input').attr('value', deviceCookie); 
        }
    }
    if(jQuery('.extensionid').length > 0){

        if(extensionidQSP){
           jQuery('li.extensionid').find('input').val(extensionidQSP);
        }else{
           jQuery('li.extensionid').find('input').attr('value', extensionidCookie); 
        }
    }

    // add Foundation 6 Reveal modal open attribute to elements having the modal-w2l-form class
    jQuery('.modal-w2l-form').attr('data-open', 'footer-w2l-modal').click(function(event) {
        event.preventDefault();
    });

    // add Foundation 6 Reveal modal open attribute to elements having the modal-registration-form class
    jQuery('.modalRegistration').attr('data-open', 'footer-registration-modal').click(function(event) {
        event.preventDefault();
    });

    // add Foundation 6 Reveal modal open attribute to elements having the footer-demo-request-modal class
    jQuery('.demo-request').attr('data-open', 'footer-demo-request-modal').click(function(event) {
      event.preventDefault();
    });

    // genie product registration form
    jQuery('.contact-policy-hover').hoverIntent(openContactPolicyTooltip,closeContactPolicyTooltip);

	function openContactPolicyTooltip() { 
		jQuery('.contact-policy-tooltip').fadeIn();
	}

	function closeContactPolicyTooltip() {
		jQuery('.contact-policy-tooltip').fadeOut();
	}
 
});