// Salesgenie Pre/Post Auth

// pre auth stuff!
jQuery('.post-auth').hide();
jQuery('.pre-auth').show();
    
// get the CmsCookie
var cmsCookie = Cookies.get('CmsCookie');

if (cmsCookie != null && cmsCookie.length > 0) {

  	// decode and parse the cookie as JSON        
	var cmsCookieParsed = JSON.parse(decodeURIComponent(cmsCookie));
	
	// check the IsAuthenticated cookie value and
	// show/hide elements based on that value
	if (cmsCookieParsed.IsAuthenticated) {
	        
		// post auth stuff!
    	jQuery('.post-auth').show();
    	jQuery('.pre-auth').hide();
        
	} 
	
}